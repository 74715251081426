<template>
  <div>
    <div class="head-area">
      <div class="head-label">
        <div style="padding-top: 80px;">
          校企合作服务
        </div>
        <p style="font-size: 16px;padding-bottom: 50px;padding-top: 10px">应社会所需，与市场接轨，与企业合作，有针对性地培养人才，注重学生实践技能，学校与企业资源、信息共享达成 “双赢”</p>
      </div>
      <div class="head-btn">
        <div @click="addneed()" style="background-color: #0db88a;padding: 10px 30px;border-radius: 2px;cursor:pointer;">
          发布需求
        </div>
      </div>
    </div>
    <div style="width: 1440px;margin: 0 auto;background-color: white">
      <img :src="png_school1" alt="" style="">

      <div style="width: 100%;background-color: #F5F7FA;padding: 60px 0">
        <div class="title" style="margin-top: 0">案例展示</div>
        <div class="intro"><span style="color: #0db88a;font-size: 16px;font-weight: 500;">1000+</span>合作企业，精选实战案例一览</div>
        <div class="company-list">
          <div class="logo-list" >
            <img :src="baseURL + item" v-for="item in this.case" style="width: 270px;height: 360px;margin-bottom: 40px">
          </div>
        </div>
      </div>
<!--      <img :src="png_school2" alt="" style="">-->

      <div style="width: 1440px;padding-bottom: 40px">
        <div class="title">我们的客户</div>
        <div class="intro">我们已经为百余家企业提供了职业素养、技能管理、人力资源等方面的优质培训，遍布金融、地产、汽车等行业</div>
        <div class="company-list">
          <div class="logo-list" >
            <img :src="baseURL + item" v-for="item in customer" style="width: 228px;height: 80px;margin-bottom: 20px">
          </div>
        </div>
<!--        <img :src="png_labor3" alt="" style="">-->
      </div>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/images/company/Train_logo1.png'
import png_school1 from '@/assets/images/company/School1.png'
import png_school2 from '@/assets/images/company/School2.png'
import png_labor3 from '@/assets/images/company/labor3.png'
import {SchoolCooper_url, baseURL} from '../../utils/request/apis'

export default {
  name: "SchoolCooper",
  data(){
    return{
      logo,
      png_school1,
      png_school2,
      png_labor3,
      case:[],
      customer:[],
      baseURL :'',
    }
  },
  created() {
    this.baseURL = baseURL;
    this.getListData()
  },
  methods: {
    addneed(){
      const url = this.$router.resolve({ name: 'schoolandcompany' })
      window.open(url.href)
    },
    getListData(){
      var caseTemp = '';
      var customerTemp = '';
      this.$http.get(SchoolCooper_url).then(res => {
        if(res.success){
          console.log("1111",res);
          const temp = res.result.records;
          for(var i = 0 ; i < temp.length ; i++){
            if(temp[i].type == 0){
              caseTemp += temp[i].image;
              caseTemp += ','
            }
            if(temp[i].type == 1){
              customerTemp += temp[i].image
              customerTemp += ','
            }
          }
          if(caseTemp.endsWith(',')){
            caseTemp = caseTemp.substring(0,caseTemp.length-1);
          }
          if(customerTemp.endsWith(',')){
            customerTemp = customerTemp.substring(0,customerTemp.length-1);
          }
          this.case = caseTemp.split(',');
          this.customer = customerTemp.split(',');
          console.log("case",this.case);
          console.log("customer",this.customer);
        } else {
          this.$message(res.message);
        }
      })
    },
  }
}
</script>

<style scoped>
.head-area{
  width: 100%;
  height: 300px;
  background: white;
  padding: 0px;
  background-image: url('../../assets/images/company/companyHead.jpg');
  background-size: 100% 100%;
}
.head-label {
  /*display:flex;*/
  text-align:center;
  align-items:center;
  justify-content: center;
  color: #fff;
  /*line-height:240px;*/
  font-size:30px;
}
.head-btn {
  display:flex;
  text-align:center;
  align-items:center;
  justify-content: center;
  color: #fff;
  /* background-color:#0db88a; */
  /* width: fit-content; */
  font-size:13px;
  margin-top: 0px;
}
.title{
  margin-top: 60px;
  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 56px;
  color: #303133;
  font-feature-settings: 'tnum' on, 'lnum' on;
}
.intro{
  margin-top: 8px;
  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: #606266;
}
.company-list{
  width: 1200px;
  margin: 0 auto;
  margin-top: 32px
}
.logo-list{
  text-align: left;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
</style>
